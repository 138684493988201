<template>
	<v-row class="ma-0 align-center" style="padding: 4px 16px">
		<div class="title d-flex">
			<v-app-bar-nav-icon v-if="sidebarMini && $vuetify.display.mdAndUp" @click="$emit('showSidebar')">
				<v-icon size="20" class="stroke-black"> $burger </v-icon>
			</v-app-bar-nav-icon>
			<v-app-bar-nav-icon
				v-if="$vuetify.display.smAndDown"
				@click="$emit('showSidebar')"></v-app-bar-nav-icon>
			<v-col class="ml-3 pa-0 d-flex flex-column justify-center">
				<span class="fz-22 lh-5 font-weight-medium">{{ pageTitle }}</span>
				<span v-if="!isLostConnection" class="fz-14 font-weight-normal text-grey" style="font-weight: 500">
					{{ pageSubTitle }}
				</span>
				<span v-else class="fz-14 lh-5 font-weight-normal" style="color: red">
					Connection lost, try to update page, or wait for reconnect...
				</span>
			</v-col>
		</div>
		<v-spacer />
		<span class="align-center mr-7">
			<v-icon
				v-if="items.length || minLastDate || lastCollection"
				size="14"
				class="mr-2 pointer"
				:class="{refreshSpin: loadingStat}"
				@click="getLastStat">
				$refreshData
			</v-icon>
			<AppBarTableStat
				:key="key"
				:min-last-date="minLastDate"
				:last-collection="lastCollection"
				:items="itemsArr"
				:search-obj="searchObj" />
		</span>
		<v-divider vertical />
		<div class="d-flex align-center">
			<v-menu offset="4" content-class="rounded-lg border-lightgray">
				<template #activator="{props}">
					<div class="ma-0 d-flex align-center ml-7">
						<v-avatar v-if="me" size="32" class="rounded-lg">
							<v-img v-if="me && me.avatar" :src="me.avatar" />
							<AvatarsItem v-else :item="me" custom-class="no-avatar-style_Item" />
						</v-avatar>
						<span class="mx-4 text-body-2 text-black d-flex align-center">
							{{ me ? me.fullName : 'No name' }}
						</span>
						<v-sheet
							v-bind="props"
							class="d-flex align-center btn-chevron-down chevron-item pointer"
							height="20"
							elevation="0"
							width="20"
							style="border-radius: 6px"
							:class="{
								active: props['aria-expanded'] === 'true',
							}"
							@click.stop>
							<v-icon size="20" class="stroke-grey"> $chevronDown </v-icon>
						</v-sheet>
					</div>
				</template>
				<v-list max-width="300" width="300" class="mt-2">
					<v-card-title class="bb-gray pl-5 py-3 fz-10 text-black" style="border-bottom: 1px solid #e5e8ef">
						<div class="d-flex flex-row" style="width: 100%">
							<span class="fz-14 text-grey font-weight-light" style="width: 50%"> Team </span>
							<span class="fz-14 font-weight-light">
								{{ me && me.team && me.team.name }}
							</span>
						</div>
						<div v-if="me && me.teamRole && me.teamRole.name" class="d-flex flex-row" style="width: 100%">
							<span class="dropdown-key fz-14 text-grey font-weight-light" style="width: 50%"> Role </span>
							<span class="font-weight-light fz-14">
								{{ me && me.teamRole && me.teamRole.name }}
							</span>
						</div>
						<div v-if="me && formatBirthday" class="d-flex flex-row" style="width: 100%">
							<span class="font-weight-light fz-14 text-grey" style="width: 50%">Date of birth</span>
							<span class="font-weight-light fz-14">
								{{ me && formatBirthday[0] }}
							</span>
							<span class="text-grey fz-14 mx-1">/</span>
							<span class="font-weight-light fz-14">
								{{ me && formatBirthday[1] }}
							</span>
							<span class="text-grey fz-14 mx-1">/</span>
							<span class="font-weight-light fz-14">
								{{ me && formatBirthday[2] }}
							</span>
						</div>
					</v-card-title>
					<v-card-text class="px-5 pb-0">
						<v-list class="pa-0">
							<v-list-item v-if="me && me.email" class="pa-0 mt-3" style="min-height: 40px">
								<v-icon>$gmail</v-icon>
								<span class="ml-2">{{ me.email }}</span>
							</v-list-item>
							<v-list-item v-if="me && me.telegram" class="pa-0" style="min-height: 40px">
								<v-icon>$telegram</v-icon>
								<span class="ml-2">{{ me.telegram }}</span>
							</v-list-item>
							<v-list-item v-if="me && me.phone" class="pa-0 pb-3" style="min-height: 40px">
								<v-icon>$phone</v-icon>
								<span class="ml-2">{{ me && me.phone }}</span>
							</v-list-item>
						</v-list>
					</v-card-text>
					<v-card-actions
						class="px-5 py-2 list-item-hover pointer"
						style="border-top: 1px solid #e5e8ef"
						@click="logout">
						<v-icon>$logout</v-icon>
						<span class="ml-2 fz-14" style="color: #fc5a5a">Logout</span>
					</v-card-actions>
				</v-list>
			</v-menu>
		</div>
	</v-row>
</template>
<script>
import formatDate from '@/helpers/formatDate';
import AppBarTableStat from '@/components/AppBarTableStat';
import gql from 'graphql-tag';
import AvatarsItem from '@/components/md-ui/table/item/AvatarsItem';
export default {
	name: 'AppBar',
	components: {
		AppBarTableStat,
		AvatarsItem,
	},
	props: ['sidebarMini', 'me', 'isLostConnection'],
	emits: ['showSidebar'],
	data() {
		return {
			key: 0,
			christmasMode: localStorage.getItem('md_christmas'),
			renderButton: true,
			searchValue: '',
			searchShow: false,
			pageTitle: null,
			pageSubTitle: null,
			minLastDate: '',
			items: [],
			loadingStat: false,
			statName: null,
			lastCollection: '',
			searchObj: {
				searchTarget: '',
			},
		};
	},
	computed: {
		formatBirthday() {
			if (this.me.birthday) {
				return this.formatDate(this.me.birthday).split('.');
			} else return null;
		},
		searchWidth() {
			return this.searchShow || this.searchValue ? '280px' : '48px';
		},
		globalLastStat() {
			return this.$store.state.globalLastStat;
		},
		itemsArr() {
			let arr = this.items;
			if (this.searchObj.searchTarget) {
				arr = arr.filter((el) => el.target.toLowerCase().includes(this.searchObj.searchTarget.toLowerCase()));
			}
			return arr;
		},
	},
	watch: {
		$route: {
			immediate: true, // Вызвать наблюдатель сразу после создания компонента
			handler(route) {
				this.pageTitle = route.name;
				let str = '';
				if (route.meta.path) {
					for (let i = 0; i < route.meta.path.length; i++) {
						if (i) str += ' — ';
						str += route.meta.path[i];
					}
				}
				if (route.name === 'Monetization') {
					this.statName = 'RL';
					this.clearStat();
					this.getLastStat();
				} else this.statName = null;

				{
					this.pageSubTitle = str;
				}
				if (!route.path.includes('brief') || route.path.includes('brief/analytics')) {
					this.clearStat();
				}
			},
		},
		globalLastStat: {
			handler() {
				this.getLastStat();
			},
			deep: true,
		},
	},
	methods: {
		handleChristmasMode() {
			console.clear();
			console.log('handling');
			console.log(localStorage.getItem('md_christmas'));
			console.log(this.christmasMode);
			if (localStorage.getItem('md_christmas') === 'enabled') {
				localStorage.setItem('md_christmas', 'disabled');
				this.christmasMode = 'disabled';
				this.$store.commit('updateChristmasMode', 'disabled');
			} else {
				localStorage.setItem('md_christmas', 'enabled');
				this.christmasMode = 'enabled';
				this.$store.commit('updateChristmasMode', 'enabled');
			}
		},
		async logout() {
			await this.$apollo
				.mutate({
					mutation: gql`
            mutation logout {
              auth {
                logout
              }
            }
          `,
					fetchPolicy: 'no-cache',
				})
				.then(() => {
					document.cookie = 'Login=false';
					this.$router.push('/login');
				});
		},
		formatDate,
		async getLastStat() {
			this.loadingStat = true;
			await this.$apollo
				.query({
					query: gql`
            query getLastStat($input: CollectionInfoInput!, $name: TaskNameEnum) {
              collection {
                info(input: $input) {
                  minLastDate
                  mostInfo {
                    analyticCollection
                    contentLastUpdate
                    keywordCollection
                    gscMaxDate
                    lactCheckIndexDate
                    lastUpdate
                    pageCollection
                    target
                    trafficCollection
                  }
                }
                lastCollection(name: $name)
              }
            }
          `,
					variables: {
						input: {
							categories:
                this.globalLastStat && this.globalLastStat.category ? this.globalLastStat.category : null,
							pageIds: null,
							pageUrls:
                this.globalLastStat && this.globalLastStat.pageUrls ? this.globalLastStat.pageUrls : null,
							projectDomains:
                this.globalLastStat && this.globalLastStat.projectDomains
                	? this.globalLastStat.projectDomains
                	: null,
							path: this.globalLastStat && this.globalLastStat.path ? this.globalLastStat.path : null,
							projectIds:
                this.globalLastStat && this.globalLastStat.projectIds.length
                	? this.globalLastStat.projectIds
                	: null,
						},
						name: this.statName,
					},
					fetchPolicy: 'no-cache',
				})
				.then((data) => {
					this.key++;
					this.loadingStat = false;
					this.minLastDate = data.data.collection.info.minLastDate
						? formatDate(data.data.collection.info.minLastDate, 'MD Time')
						: null;
					this.lastCollection = data.data.collection.lastCollection
						? formatDate(data.data.collection.lastCollection, 'MD Time')
						: null;
					this.items = data.data.collection.info.mostInfo;
				})
				.catch(() => {
					this.loadingStat = false;
				});
		},
		clearStat() {
			this.globalLastStat.projectIds = [];
			this.globalLastStat.pageUrls = [];
			this.globalLastStat.projectDomains = [];
			this.globalLastStat.path = '';
			this.globalLastStat.category = [];
			this.lastCollection = null;
			this.minLastDate = '';
			this.items = [];
		},
	},
};
</script>

<style lang="scss" scoped>
$globe-width: 8px;
$globe-height: 16px;
$globe-spacing: 80px;
$globe-spread: 2px;
$light-off-opacity: 0.4;

.refreshSpin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  border: none !important;
  background-color: transparent !important;
}

.search-input {
  transition: 0.26s;

  .v-input__prepend-inner,
  .v-input__append-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .v-input__slot {
    padding: 0 12px !important;
  }
}

.search-pointer {
  .v-input__slot {
    cursor: pointer !important;
  }
}

.chevron-item {
  background: #f5f7fb !important;
}

.active {
  background: #8f20ef !important;
}

.dropdown-key {
}

body {
  background: #000;
}

.lightrope {
  left: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: -2;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  top: 50px;

  li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: $globe-width;
    height: $globe-height;
    border-radius: 50%;
    margin: $globe-spacing / 2;
    display: inline-block;
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px ($globe-height / 6) ($globe-width * 2) $globe-spread rgba(0, 247, 165, 1);
    animation-name: flash-1;
    animation-duration: 2s;

    &:nth-child(2n + 1) {
      background: rgba(0, 255, 255, 1);
      box-shadow: 0px ($globe-height / 6) ($globe-width * 2) $globe-spread rgba(0, 255, 255, 0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }

    &:nth-child(4n + 2) {
      background: rgba(247, 0, 148, 1);
      box-shadow: 0px ($globe-height / 6) ($globe-width * 2) $globe-spread rgba(247, 0, 148, 1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }

    &:nth-child(odd) {
      animation-duration: 1.8s;
    }

    &:nth-child(3n + 1) {
      animation-duration: 1.4s;
    }

    &:before {
      content: '';
      position: absolute;
      background: #222;
      width: ($globe-width - 2);
      height: ($globe-height / 3);
      border-radius: 3px;
      top: (0 - ($globe-height / 6));
      left: 1px;
    }

    &:after {
      content: '';
      top: (0 - $globe-height / 2);
      left: $globe-width - 3;
      position: absolute;
      width: $globe-spacing + 12;
      height: ($globe-height / 3 * 3);
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }

    &:last-child:after {
      content: none;
    }

    &:first-child {
      margin-left: -$globe-spacing;
    }
  }
}

@keyframes flash-1 {
  0%,
  100% {
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread rgba(0, 247, 165, 1);
  }
  50% {
    background: rgba(0, 247, 165, $light-off-opacity);
    box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-2 {
  0%,
  100% {
    background: rgba(0, 255, 255, 1);
    box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread rgba(0, 255, 255, 1);
  }
  50% {
    background: rgba(0, 255, 255, $light-off-opacity);
    box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-3 {
  0%,
  100% {
    background: rgba(247, 0, 148, 1);
    box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread rgba(247, 0, 148, 1);
  }
  50% {
    background: rgba(247, 0, 148, $light-off-opacity);
    box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread rgba(247, 0, 148, 0.2);
  }
}
</style>
