import gql from 'graphql-tag';

export default async function frontErrorHandler(
	error,
	instance,
	info,
	componentName,
) {
	if (process.env.NODE_ENV !== 'development') {
		await instance.$apollo
			.mutate({
				mutation: gql`
          mutation ReportBug($input: UserEventLogCreateInput!) {
            technical {
              logging {
                createUserBug(input: $input)
              }
            }
          }
        `,
				fetchPolicy: 'no-cache',
				variables: {
					input: {
						exception:
              info && componentName ?
              	`Event: ${info}; ${error}; component: ${componentName}` :
              	error || error.message,
						path: instance.$route ? instance.$route.path : window.location.pathname,
					},
				},
			})
			.then(() => {
				if (instance.addSnack) {
					instance.addSnack(
						'Error',
						'Error',
						'Oops! Something goes wrong in app. Message was sent to developers!',
					);
				}
			})
			.catch(() => {
				console.log('Friendly fire');
			});
	}
}
