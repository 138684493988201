<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.3333 1.66669V5.00002" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M6.66667 1.66669L6.66667 5.00002" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M2.5 7.50004L17.5 7.50004" stroke-linecap="round" stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.8333 3.33337L4.16667 3.33337C3.24583 3.33337 2.5 4.07921 2.5 5.00004L2.5 15.8334C2.5 16.7542 3.24583 17.5 4.16667 17.5H15.8333C16.7542 17.5 17.5 16.7542 17.5 15.8334V5.00004C17.5 4.07921 16.7542 3.33337 15.8333 3.33337Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M7.5 7.5L7.5 17.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M12.5 7.5L12.5 17.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M2.5 12.5L17.5 12.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'menuMarketingPlan',
};
</script>

<style scoped></style>
