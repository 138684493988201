<template>
	<v-menu
		offset="4"
		:close-on-content-click="false"
		content-class="box-shadow-12 mt-1 rounded-3"
		style="z-index: 2100">
		<template #activator="{props}">
			<span v-bind="props" :class="{'cursor-unset': !items.length}">
				<span class="text-grey fz-12" :class="{'text-hover-primary pointer': items.length}">
					{{ lastCollection ? lastCollection : minLastDate }}
				</span>
				<span
					v-if="items.length && !minLastDate && !lastCollection"
					class="text-grey fz-12"
					:class="{'text-hover-primary pointer': items.length}">
					⚠️{{ lastDate }}
				</span>
			</span>
		</template>
		<v-sheet
			width="900"
			max-height="500"
			class="rounded-3 pa-7 scroll"
			style="box-shadow: 0px 8px 24px rgba(42, 53, 90, 0.1)">
			<p v-if="modalLoading" class="fz-14 text-center text-grey">Stat loading...</p>
			<v-data-table
				v-else
				v-model:options="modalOptions"
				fixed-header
				hide-default-footer
				class="table-small"
				:headers="modalHeadersUrl"
				:items="items">
				<template #header.target="">
					<v-row class="ma-0 align-center flex-nowrap" style="max-height: 40px">
						<v-text-field
							v-model="searchObj.searchTarget"
							placeholder="Target"
							hide-details
							max-width="150"
							min-width="110"
							class="appBarTableInput"
							@click.stop>
							<template #prepend-inner="">
								<v-icon icon="$searchIcon" class="stroke-black" />
							</template>
						</v-text-field>
						<sort-f-icon :options="modalOptions" value="target" />
					</v-row>
				</template>
				<template #header.analyticCollection="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap">
						<span class="fz-12 font-weight-medium text-nowrap">Analytic Collection</span>
						<sort-f-icon :options="modalOptions" value="analyticCollection" />
					</v-row>
				</template>
				<template #header.contentLastUpdate="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap sortNone">
						<span class="fz-12 font-weight-medium text-nowrap">Content Last Update</span>
						<sort-f-icon :options="modalOptions" value="contentLastUpdate" />
					</v-row>
				</template>
				<template #header.gscMaxDate="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap">
						<span class="fz-12 font-weight-medium text-nowrap">GSC Max Date</span>
						<sort-f-icon :options="modalOptions" value="gscMaxDate" />
					</v-row>
				</template>
				<template #header.keywordCollection="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap">
						<span class="fz-12 font-weight-medium text-nowrap">Keyword Collection</span>
						<sort-f-icon :options="modalOptions" value="keywordCollection" />
					</v-row>
				</template>
				<template #header.lactCheckIndexDate="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap">
						<span class="fz-12 font-weight-medium text-nowrap">Last Check Index Date</span>
						<sort-f-icon :options="modalOptions" value="lactCheckIndexDate" />
					</v-row>
				</template>
				<template #header.lastUpdate="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap">
						<span class="fz-12 font-weight-medium text-nowrap">Last Update</span>
						<sort-f-icon :options="modalOptions" value="lastUpdate" />
					</v-row>
				</template>
				<template #header.pageCollection="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap">
						<span class="fz-12 font-weight-medium text-nowrap">Page Collection</span>
						<sort-f-icon :options="modalOptions" value="pageCollection" />
					</v-row>
				</template>
				<template #header.trafficCollection="">
					<v-row class="ma-0 flex-nowrap align-center flex-nowrap">
						<span class="fz-12 font-weight-medium text-nowrap">Traffic Collection</span>
						<sort-f-icon :options="modalOptions" value="trafficCollection" />
					</v-row>
				</template>
				<template #item.target="{item}">
					<span class="text-no-wrap">{{ item.target }}</span>
				</template>
				<template #item.analyticCollection="{item}">
					<span class="text-no-wrap">
						{{ item.analyticCollection ? formatDate(item.analyticCollection, 'MD Time') : '---' }}
					</span>
				</template>
				<template #item.contentLastUpdate="{item}">
					<span class="text-no-wrap">
						{{ item.contentLastUpdate ? formatDate(item.contentLastUpdate, 'MD Time') : '---' }}
					</span>
				</template>
				<template #item.gscMaxDate="{item}">
					<span class="text-no-wrap">
						{{ item.gscMaxDate ? formatDate(item.gscMaxDate, 'MD Time') : '---' }}
					</span>
				</template>
				<template #item.keywordCollection="{item}">
					<span class="text-no-wrap">
						{{ item.keywordCollection ? formatDate(item.keywordCollection, 'MD Time') : '---' }}
					</span>
				</template>
				<template #item.lactCheckIndexDate="{item}">
					<span class="text-no-wrap">
						{{ item.lactCheckIndexDate ? formatDate(item.lactCheckIndexDate, 'MD Time') : '---' }}
					</span>
				</template>
				<template #item.lastUpdate="{item}">
					<span class="text-no-wrap">
						{{ item.lastUpdate ? formatDate(item.lastUpdate, 'MD Time') : '---' }}
					</span>
				</template>
				<template #item.pageCollection="{item}">
					<span class="text-no-wrap">
						{{ item.pageCollection ? formatDate(item.pageCollection, 'MD Time') : '---' }}
					</span>
				</template>
				<template #item.trafficCollection="{item}">
					<span class="text-no-wrap">
						{{ item.trafficCollection ? formatDate(item.trafficCollection, 'MD Time') : '---' }}
					</span>
				</template>
			</v-data-table>
		</v-sheet>
	</v-menu>
</template>
<script>
import formatDate from '@/helpers/formatDate';
import sortFIcon from '@/components/tables/SortFIcon';
export default {
	name: 'AppBarTableStat',
	components: {sortFIcon},
	props: ['items', 'minLastDate', 'lastCollection', 'searchObj'],
	data() {
		return {
			modalOptions: {
				sortBy: [],
			},
			modalLoading: false,
			modalHeadersUrl: [
				{
					text: 'Target',
					value: 'target',
					filterable: false,
					sortable: true,
				},
				{
					text: 'Analytic Collection',
					value: 'analyticCollection',
					filterable: false,
					sortable: true,
				},
				{
					text: 'Content Last Update',
					value: 'contentLastUpdate',
					filterable: false,
					sortable: true,
				},
				{
					text: 'GSC Max Date',
					value: 'gscMaxDate',
					filterable: false,
					sortable: true,
				},
				{
					text: 'Keyword Collection',
					value: 'keywordCollection',
					filterable: false,
					sortable: true,
				},
				{
					text: 'Last Check Index Date',
					value: 'lactCheckIndexDate',
					filterable: false,
					sortable: true,
				},
				{
					text: 'Last Update',
					value: 'lastUpdate',
					filterable: false,
					sortable: true,
				},
				{
					text: 'Page Collection',
					value: 'pageCollection',
					filterable: false,
					sortable: true,
				},
				{
					text: 'Traffic Collection',
					value: 'trafficCollection',
					filterable: false,
					sortable: true,
				},
			],
		};
	},
	computed: {
		lastDate() {
			return formatDate(
				this.items.find((item) => item.contentLastUpdate)?.contentLastUpdate || null,
				'MD Time'
			);
		},
	},
	methods: {
		formatDate,
	},
};
</script>

<style>
.appBarTableInput input {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #2a355a !important;
}
.appBarTableInput input::placeholder {
  color: #2a355a !important;
  opacity: 1 !important;
}
</style>
