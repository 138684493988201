export const monetizationModule = {
	state: () => ({
		fromProject: false,
		id: null,
		regionId: null,
		date: '',
		currentDate: '',
		compareMonths: null,
		compareSelect: null,
		type: null,
		btnToggle: null,
		projectIds: [],
		groupBy: '',
		team: null,
		backFromBrands: false,
	}),
	getters: {
		getBackFromBrands(state) {
			return state.backFromBrands;
		},
		getTeam(state) {
			return state.team;
		},
		getGroupBy(state) {
			return state.groupBy;
		},
		getProjectIds(state) {
			return state.projectIds;
		},
		getCompareMonths(state) {
			return state.compareMonths;
		},
		getCompareSelect(state) {
			return state.compareSelect;
		},
		getBtnToggle(state) {
			return state.btnToggle;
		},
		getFromProject(state) {
			return state.fromProject;
		},
		getId(state) {
			return state.id;
		},
		getDate(state) {
			return state.date;
		},
		getCurrentDate(state) {
			return state.currentDate;
		},
		getType(state) {
			return state.type;
		},
		getRegionId(state) {
			return state.regionId;
		},
	},
	mutations: {
		setBackFromBrands(state, backFromBrands) {
			state.backFromBrands = backFromBrands;
		},
		setTeam(state, team) {
			state.team = team;
		},
		setGroupBy(state, groupBy) {
			state.groupBy = groupBy;
		},
		setProjectIds(state, projectIds) {
			state.projectIds = projectIds;
		},
		setCompareMonths(state, compareMonths) {
			state.compareMonths = compareMonths;
		},
		setCompareSelect(state, compareSelect) {
			state.compareSelect = compareSelect;
		},
		setBtnToggle(state, btnToggle) {
			state.btnToggle = btnToggle;
		},
		setFromProject(state, fromProject) {
			state.fromProject = fromProject;
		},
		setId(state, id) {
			state.id = id;
		},
		setDate(state, date) {
			state.date = date;
		},
		setCurrentDate(state, currentDate) {
			state.currentDate = currentDate;
		},
		setType(state, type) {
			state.type = type;
		},
		setRegionId(state, regionId) {
			state.regionId = regionId;
		},
	},
	actions: {},
	namespaced: true,
};
