<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.33337 14.1225V15.8333C3.33337 16.7542 4.07921 17.5 5.00004 17.5H15C15.9209 17.5 16.6667 16.7542 16.6667 15.8333V8.95334"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M3.33337 11.2058L3.33337 4.16667C3.33337 3.24583 4.07921 2.5 5.00004 2.5L15 2.5C15.9209 2.5 16.6667 3.24583 16.6667 4.16667V6.03667"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M4.35952 11.633C4.92904 12.2025 4.92904 13.1259 4.35952 13.6954C3.79001 14.2649 2.86664 14.2649 2.29713 13.6954C1.72762 13.1259 1.72762 12.2025 2.29713 11.633C2.86665 11.0635 3.79001 11.0635 4.35952 11.633"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.7908 12.4975C10.7908 11.6925 11.4433 11.0392 12.2491 11.0392C13.0549 11.0392 13.7074 11.6917 13.7074 12.4975C13.7074 13.3033 13.0549 13.9558 12.2491 13.9558C11.4466 13.9592 10.7941 13.3117 10.7908 12.5092C10.7908 12.505 10.7908 12.5017 10.7908 12.4975Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M8.85452 6.46379C9.42403 7.0333 9.42403 7.95667 8.85452 8.52618C8.285 9.0957 7.36164 9.0957 6.79213 8.52618C6.22261 7.95667 6.22261 7.0333 6.79213 6.46379C7.36164 5.89427 8.28501 5.89427 8.85452 6.46379"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.7028 6.46379C18.2723 7.0333 18.2723 7.95667 17.7028 8.52618C17.1333 9.0957 16.2099 9.0957 15.6404 8.52618C15.0709 7.95667 15.0709 7.0333 15.6404 6.46379C16.2099 5.89427 17.1333 5.89427 17.7028 6.46379"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M15.6584 8.54166L13.2417 11.4333" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M4.31665 11.5917L6.83332 8.55835" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M11.2583 11.4334L8.81665 8.55835" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'menuReports',
};
</script>

<style scoped></style>
