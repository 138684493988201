<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.6667 5.83333L9.16667 5.83333C8.70667 5.83333 8.33333 5.46 8.33333 5V3.33333C8.33333 2.87333 8.70667 2.5 9.16667 2.5L16.6667 2.5C17.1267 2.5 17.5 2.87333 17.5 3.33333V5C17.5 5.46 17.1267 5.83333 16.6667 5.83333Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.6667 11.6667L9.16667 11.6667C8.70667 11.6667 8.33333 11.2934 8.33333 10.8334V9.16671C8.33333 8.70671 8.70667 8.33337 9.16667 8.33337L16.6667 8.33337C17.1267 8.33337 17.5 8.70671 17.5 9.16671V10.8334C17.5 11.2934 17.1267 11.6667 16.6667 11.6667Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.6667 17.5H9.16667C8.70667 17.5 8.33333 17.1266 8.33333 16.6666V15C8.33333 14.54 8.70667 14.1666 9.16667 14.1666L16.6667 14.1666C17.1267 14.1666 17.5 14.54 17.5 15V16.6666C17.5 17.1266 17.1267 17.5 16.6667 17.5Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5 5.83333H3.33333C2.87333 5.83333 2.5 5.46 2.5 5L2.5 3.33333C2.5 2.87333 2.87333 2.5 3.33333 2.5L5 2.5C5.46 2.5 5.83333 2.87333 5.83333 3.33333V5C5.83333 5.46 5.46 5.83333 5 5.83333Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5 11.6667H3.33333C2.87333 11.6667 2.5 11.2934 2.5 10.8334L2.5 9.16671C2.5 8.70671 2.87333 8.33337 3.33333 8.33337H5C5.46 8.33337 5.83333 8.70671 5.83333 9.16671V10.8334C5.83333 11.2934 5.46 11.6667 5 11.6667Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5 17.5H3.33333C2.87333 17.5 2.5 17.1266 2.5 16.6666L2.5 15C2.5 14.54 2.87333 14.1666 3.33333 14.1666H5C5.46 14.1666 5.83333 14.54 5.83333 15V16.6666C5.83333 17.1266 5.46 17.5 5 17.5Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>
<script>
export default {
	name: 'menuAffiliateMarketing',
};
</script>
<style scoped></style>
