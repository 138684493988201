<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.6667 5.83333L8.9225 3.08916C8.59667 2.76333 8.06917 2.76333 7.74417 3.08916L5 5.83333"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M10 4.16666L11.0775 3.08916C11.4033 2.76333 11.9308 2.76333 12.2558 3.08916L15 5.83333"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.5 10H14.5159C13.8625 10 13.3334 10.5292 13.3334 11.1825V12.1508C13.3334 12.8042 13.8625 13.3333 14.5159 13.3333H17.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.8333 17.5H4.16667C3.24583 17.5 2.5 16.7541 2.5 15.8333L2.5 7.49998C2.5 6.57915 3.24583 5.83331 4.16667 5.83331L15.8333 5.83331C16.7542 5.83331 17.5 6.57915 17.5 7.49998V15.8333C17.5 16.7541 16.7542 17.5 15.8333 17.5Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'menuFinance',
};
</script>

<style scoped></style>
