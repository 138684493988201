<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.5 4.16667H17.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M2.5 9.99967H17.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M2.5 15.8337H17.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'burger',
};
</script>

<style scoped></style>
