<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.3795 8.62058C12.1413 9.38243 12.1413 10.6176 11.3795 11.3795C10.6176 12.1413 9.38242 12.1413 8.62058 11.3795C7.85873 10.6176 7.85873 9.38242 8.62058 8.62058C9.38243 7.85873 10.6176 7.85873 11.3795 8.62058"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.8741 8.75841C18.5598 9.44411 18.5598 10.5558 17.8741 11.2415C17.1884 11.9272 16.0767 11.9272 15.391 11.2415C14.7053 10.5558 14.7053 9.44411 15.391 8.75841C16.0767 8.07272 17.1884 8.07272 17.8741 8.75841"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M4.60909 8.75841C5.29479 9.44411 5.29479 10.5558 4.60909 11.2415C3.9234 11.9272 2.81167 11.9272 2.12597 11.2415C1.44027 10.5558 1.44027 9.44411 2.12597 8.75841C2.81166 8.07272 3.92339 8.07272 4.60909 8.75841"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.5575 3.01427C15.2432 3.69997 15.2432 4.8117 14.5575 5.4974C13.8718 6.18309 12.76 6.18309 12.0743 5.4974C11.3886 4.8117 11.3886 3.69997 12.0743 3.01427C12.76 2.32858 13.8718 2.32858 14.5575 3.01427"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7.92574 14.5026C8.61144 15.1882 8.61144 16.3 7.92574 16.9857C7.24005 17.6714 6.12832 17.6714 5.44262 16.9857C4.75692 16.3 4.75692 15.1883 5.44262 14.5026C6.12831 13.8169 7.24004 13.8169 7.92574 14.5026"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7.92574 3.01427C8.61144 3.69997 8.61144 4.8117 7.92574 5.4974C7.24005 6.18309 6.12832 6.18309 5.44262 5.4974C4.75692 4.8117 4.75692 3.69997 5.44262 3.01427C6.12831 2.32858 7.24004 2.32858 7.92574 3.01427"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.5575 14.5026C15.2432 15.1882 15.2432 16.3 14.5575 16.9857C13.8718 17.6714 12.76 17.6714 12.0743 16.9857C11.3886 16.3 11.3886 15.1883 12.0743 14.5026C12.76 13.8169 13.8718 13.8169 14.5575 14.5026"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M7.55835 14.225L9.02502 11.6833" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.55835 5.77502L9.02502 8.31669" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M14.8751 9.99998H11.9501" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'menuSemantics',
};
</script>

<style scoped></style>
