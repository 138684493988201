<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.1684 17.5031H5.8316C3.98988 17.5031 2.49687 16.0101 2.49687 14.1684L2.49687 7.49897C2.49687 6.57811 3.24338 5.8316 4.16423 5.8316L15.8358 5.8316C16.7566 5.8316 17.5031 6.57811 17.5031 7.49897V14.1684C17.5031 16.0101 16.0101 17.5031 14.1684 17.5031Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M6.66528 5.83161L6.66528 4.16425C6.66528 3.24339 7.41178 2.49689 8.33264 2.49689L11.6674 2.49689C12.5882 2.49689 13.3347 3.24339 13.3347 4.16425V5.83161"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<rect
			x="8.33264"
			y="9.16632"
			width="3.33472"
			height="2.50104"
			rx="0.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M11.6674 10.4168H14.5852C16.1967 10.4168 17.5031 9.11046 17.5031 7.49896V7.49896"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M8.33264 10.4168H5.41476C3.80325 10.4168 2.49687 9.11046 2.49687 7.49896V7.49896"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'management',
};
</script>

<style scoped></style>
