<template>
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M16 10.5L12 14.5L8 10.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'chevronDown',
};
</script>

<style scoped></style>
