import {ApolloClient, InMemoryCache} from '@apollo/client/core';
import {createUploadLink} from 'apollo-upload-client';
import {createApolloProvider} from '@vue/apollo-option';

const uploadLink = createUploadLink({
	uri: process.env.VUE_APP_SERVER_URL ?
		process.env.VUE_APP_SERVER_URL + '/v2/graphql/' :
		'https://marketing-desk-test.ddns.net/backend/v2/graphql/',
	credentials: 'include',
});
const cache = new InMemoryCache();

export const baseApiUrl = process.env.VUE_APP_SERVER_URL;
const apolloClient = new ApolloClient({
	link: uploadLink,
	cache,
});
const apolloProvider = createApolloProvider({
	defaultClient: apolloClient,
});

export default apolloProvider;
