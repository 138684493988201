<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.4731 9.15182C12.2867 9.96541 12.2867 11.2845 11.4731 12.0981C10.6595 12.9117 9.34041 12.9117 8.52682 12.0981C7.71323 11.2845 7.71323 9.96541 8.52682 9.15182C9.34041 8.33823 10.6595 8.33823 11.4731 9.15182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16.0448 4.15185C16.8583 4.96544 16.8583 6.28453 16.0448 7.09813C15.2312 7.91172 13.9121 7.91172 13.0985 7.09813C12.2849 6.28453 12.2849 4.96544 13.0985 4.15185C13.9121 3.33826 15.2312 3.33826 16.0448 4.15185"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M15 10.2083C16.2775 10.2083 17.5 10.6525 18.3333 11.3191"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M6.90144 4.15185C7.71503 4.96544 7.71503 6.28453 6.90144 7.09813C6.08785 7.91172 4.76876 7.91172 3.95517 7.09813C3.14157 6.28453 3.14157 4.96544 3.95517 4.15185C4.76876 3.33826 6.08785 3.33826 6.90144 4.15185"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M1.66663 11.3191C2.49996 10.6525 3.72246 10.2083 4.99996 10.2083"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M13.8257 16.4583C12.8549 15.7075 11.4599 15.2083 9.99991 15.2083C8.53991 15.2083 7.14491 15.7075 6.17407 16.4583"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'menuTeam',
};
</script>

<style scoped></style>
