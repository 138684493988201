<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.5 2.49998L8.33333 2.49998" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M2.5 4.99998L8.33333 4.99998" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M2.5 7.49998H5" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M13.2242 7.60918C14.2025 6.63084 15.7875 6.63084 16.7659 7.60918V7.60918C17.7442 8.58751 17.7442 10.1725 16.7659 11.1508L12.6017 15.315C11.6234 16.2933 10.0384 16.2933 9.06004 15.315V15.315C8.08171 14.3367 8.08171 12.7517 9.06004 11.7733L9.68254 11.1508"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7.60916 16.7667C6.63082 17.745 5.04582 17.745 4.06749 16.7667V16.7667C3.08916 15.7883 3.08916 14.2033 4.06749 13.225L8.23166 9.06084C9.20999 8.0825 10.795 8.0825 11.7733 9.06084V9.06084C12.7517 10.0392 12.7517 11.6242 11.7733 12.6025L11.125 13.25"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'menuLinkBuilding',
};
</script>

<style scoped></style>
